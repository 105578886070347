import {
  CreateFilingStateFileDTO,
  FilingStateDTO,
  FilingStateFileDTO,
  FilingStateQueryDTO,
  UserStateRatesQueryDTO,
  StateRatesDTO,
  UpdateFilingStateDTO,
  UpdateStateRatesDTO, UserStateSettingsQueryDTO, StateSettingsDTO, UpdateStateSettingsDTO
} from 'dtos/filing';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import FilingService from "services/Filing";
import { AxiosError } from "axios";

export const useFilingStates = (query: FilingStateQueryDTO, options?: UseQueryOptions<FilingStateDTO[], AxiosError>) =>
  useQuery<FilingStateDTO[], AxiosError>(['filing-states', query], () => FilingService.getAllFilingStates(query), options);

export const useFilingState = (id: string, options?: UseQueryOptions<FilingStateDTO, AxiosError>) =>
  useQuery<FilingStateDTO, AxiosError>(['filing-state', id], () => FilingService.getFilingStateById(id), options);

export const useFilingStateByAbbr = (abbr: string, options?: UseQueryOptions<FilingStateDTO, AxiosError>) =>
  useQuery<FilingStateDTO, AxiosError>(['filing-state-abbr', abbr], () => FilingService.getFilingStateByAbbr(abbr), options);

export const useFilingStateRatesForUser = (abbr: string, type: 'ed' | 'nb', query: UserStateRatesQueryDTO, options?: UseQueryOptions<StateRatesDTO, AxiosError>) =>
  useQuery<StateRatesDTO, AxiosError>(['filing-rates-for-user', abbr, query, type], () => FilingService.getFilingStateRatesForUser(abbr, query, type), options);

export const useFilingStateSettingsForUser = (abbr: string, query: UserStateSettingsQueryDTO, options?: UseQueryOptions<StateSettingsDTO, AxiosError>) =>
  useQuery<StateSettingsDTO, AxiosError>(['filing-settings-for-user', abbr, query], () => FilingService.getFilingStateSettingsForUser(abbr, query), options);

export const useUpdateFilingStateRates = (
  options?: UseMutationOptions<StateRatesDTO, AxiosError, UpdateStateRatesDTO & { filingStateId: string, type: 'ed' | 'nb' }>,
) => {
  const client = useQueryClient();

  return useMutation(
    ['update-rates'],
    ({filingStateId, type, ...data} : UpdateStateRatesDTO & { filingStateId: string, type: 'ed' | 'nb' }) =>
      FilingService.updateFilingStateRates(filingStateId, type, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing-states']);
        await client.refetchQueries(['filing-state']);
        await client.refetchQueries(['filing-state-abbr']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};
export const useUpdateFilingStateSettings = (
  options?: UseMutationOptions<StateSettingsDTO, AxiosError, UpdateStateSettingsDTO & { filingStateId: string }>,
) => {
  const client = useQueryClient();

  return useMutation(
    ['update-settings'],
    ({filingStateId, ...data} : UpdateStateSettingsDTO & { filingStateId: string }) =>
      FilingService.updateFilingStateSettings(filingStateId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing-states']);
        await client.refetchQueries(['filing-state']);
        await client.refetchQueries(['filing-state-abbr']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useUpdateFilingState = (
  options?: UseMutationOptions<FilingStateDTO, AxiosError, UpdateFilingStateDTO>,
) => {
  const client = useQueryClient();

  return useMutation(
    ['update-filing-state'],
    ({filingStateId, ...data} : UpdateFilingStateDTO & {filingStateId: string}) =>
      FilingService.updateFilingStateById(filingStateId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing-states']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useCreateFilingStateFile = (
  options?: UseMutationOptions<FilingStateFileDTO, AxiosError, CreateFilingStateFileDTO>
) => {
  const client = useQueryClient();

  return useMutation(
    ['create-filing-state-file'],
    (data: CreateFilingStateFileDTO) => FilingService.createFilingStateFile(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing-state']);
        await client.refetchQueries(['filing-state-abbr'])

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useDeleteFilingStateFile = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const client = useQueryClient();

  return useMutation(
    ['delete-filing-state-file'],
    (id: string) => FilingService.deleteFilingStateFile(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing-state']);
        await client.refetchQueries(['filing-state-abbr'])

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useDownloadFilingStateFile = (
  options?: UseMutationOptions<{ url: string }, AxiosError, string>
) => {
  return useMutation(
    ['download-filing-state-file'],
    (id: string) => FilingService.downloadFilingStateFile(id), options
  );
};
