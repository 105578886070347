export enum US_STATES {
  "AL" = "Alabama",
  "AK" = "Alaska",
  "AZ" = "Arizona",
  "AR" = "Arkansas",
  "CA" = "California",
  "CO" = "Colorado",
  "CT" = "Connecticut",
  "DE" = "Delaware",
  "DC" = "District Of Columbia",
  "FL" = "Florida",
  "GA" = "Georgia",
  "HI" = "Hawaii",
  "ID" = "Idaho",
  "IL" = "Illinois",
  "IN" = "Indiana",
  "IA" = "Iowa",
  "KS" = "Kansas",
  "KY" = "Kentucky",
  "LA" = "Louisiana",
  "ME" = "Maine",
  "MD" = "Maryland",
  "MA" = "Massachusetts",
  "MI" = "Michigan",
  "MN" = "Minnesota",
  "MS" = "Mississippi",
  "MO" = "Missouri",
  "MT" = "Montana",
  "NE" = "Nebraska",
  "NV" = "Nevada",
  "NH" = "New Hampshire",
  "NJ" = "New Jersey",
  "NM" = "New Mexico",
  "NY" = "New York",
  "NC" = "North Carolina",
  "ND" = "North Dakota",
  "OH" = "Ohio",
  "OK" = "Oklahoma",
  "OR" = "Oregon",
  "PA" = "Pennsylvania",
  "RI" = "Rhode Island",
  "SC" = "South Carolina",
  "SD" = "South Dakota",
  "TN" = "Tennessee",
  "TX" = "Texas",
  "UT" = "Utah",
  "VT" = "Vermont",
  "VA" = "Virginia",
  "WA" = "Washington",
  "WV" = "West Virginia",
  "WI" = "Wisconsin",
  "WY" = "Wyoming"
}
enum US_STATES_VI {
  "VI" = "Virgin Islands"
}
export const US_STATES_FOR_REPORTS = {...US_STATES, ...US_STATES_VI}

export enum US_STATES_ABBR {
  /** Alabama */
  AL = 'AL',
  /** Alaska */
  AK = 'AK',
  /** American Samoa */
  // AS = 'AS',
  /** Arizona */
  AZ = 'AZ',
  /** Arkansas */
  AR = 'AR',
  /** California */
  CA = 'CA',
  /** Colorado */
  CO = 'CO',
  /** Connecticut */
  CT = 'CT',
  /** Delaware */
  DE = 'DE',
  /** District Of Columbia */
  DC = 'DC',
  /** Federated States Of Micronesia */
  // FM = 'FM',
  /** Florida */
  FL = 'FL',
  /** Georgia */
  GA = 'GA',
  /** Guam */
  // GU = 'GU',
  /** Hawaii */
  HI = 'HI',
  /** Idaho */
  ID = 'ID',
  /** Illinois */
  IL = 'IL',
  /** Indiana */
  IN = 'IN',
  /** Iowa */
  IA = 'IA',
  /** Kansas */
  KS = 'KS',
  /** Kentucky */
  KY = 'KY',
  /** Louisiana */
  LA = 'LA',
  /** Maine */
  ME = 'ME',
  /** Marshall Islands */
  // MH = 'MH',
  /** Maryland */
  MD = 'MD',
  /** Massachusetts */
  MA = 'MA',
  /** Michigan */
  MI = 'MI',
  /** Minnesota */
  MN = 'MN',
  /** Mississippi */
  MS = 'MS',
  /** Missouri */
  MO = 'MO',
  /** Montana */
  MT = 'MT',
  /** Nebraska */
  NE = 'NE',
  /** Nevada */
  NV = 'NV',
  /** New Hampshire */
  NH = 'NH',
  /** New Jersey */
  NJ = 'NJ',
  /** New Mexico */
  NM = 'NM',
  /** New York */
  NY = 'NY',
  /** North Carolina */
  NC = 'NC',
  /** North Dakota */
  ND = 'ND',
  /** Northern Mariana Islands */
  // MP = 'MP',
  /** Ohio */
  OH = 'OH',
  /** Oklahoma */
  OK = 'OK',
  /** Oregon */
  OR = 'OR',
  /** Palau */
  // PW = 'PW',
  /** Pennsylvania */
  PA = 'PA',
  /** Puerto Rico */
  // PR = 'PR',
  /** Rhode Island */
  RI = 'RI',
  /** South Carolina */
  SC = 'SC',
  /** South Dakota */
  SD = 'SD',
  /** Tennessee */
  TN = 'TN',
  /** Texas */
  TX = 'TX',
  /** Utah */
  UT = 'UT',
  /** Vermont */
  VT = 'VT',
  /** Virginia */
  VA = 'VA',
  /** Washington */
  WA = 'WA',
  /** West Virginia */
  WV = 'WV',
  /** Wisconsin */
  WI = 'WI',
  /** Wyoming */
  WY = 'WY',
}
enum US_STATES_ABBR_VI {
  /** Virgin Islands */
  VI = 'VI'
}
export const US_STATES_ABBR_FOR_REPORTS =  {...US_STATES_ABBR, ...US_STATES_ABBR_VI}
