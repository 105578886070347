import Map from 'public/assets/map-2.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { FilingStateDTO } from 'dtos';
import { US_STATES_ABBR } from 'common/utils';
import { useQuery } from '@tanstack/react-query';

const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = src
  })

interface Props {
  onStateClick: (abbr: string) => void;
  validStates: FilingStateDTO[];
  isLoading?: boolean;
}

const preloadImages = async () => {
  await Promise.all(Object.values(US_STATES_ABBR).map(async abbr => {
    try {
      await preloadImage(`/assets/states-overlays/${abbr}.png`)
    } catch(e) {
      // ignore
    }
  }))
}

export const StatesMap = ({ onStateClick, validStates, isLoading }: Props) => {
  const [abbr, setAbbr] = useState(null);
  const { isLoading: isFetchingImages } = useQuery(
    ['states-images'],
    () => preloadImages(),
    {
      staleTime: Infinity,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: false
    }
  )

  const registerMouseOverHandler = useMemo(() => (event: any) => {
    const target = event.target as any;
    const { id, parentElement } = target;
    let abbr = undefined;

    if (id?.length === 2) {
      abbr = id;
    } else if (parentElement?.id?.length === 2) {
      abbr = parentElement.id;
    }

    if (abbr) {
      setAbbr(abbr);
    } else {
      setAbbr(null);
    }
  }, []);

  useEffect(() => {
    if (validStates.length !== undefined) {
      const map = document.getElementById('map');

      if (map) {
        map.childNodes.forEach((child: any) => {
          if (child.id?.length === 2 && validStates.some(state => state.stateAbbr === child.id)) {
            child.style.fill = '#7197BF';
            child.style.opacity = '1';
          } else {
            child.style.fill = '#7197BF';
            child.style.opacity = '0.6';
          }
        });
      }
    }
  }, [validStates]);

  const handleClick = useCallback(() => {
    if (abbr) {
      onStateClick(abbr);
    }
  }, [abbr, onStateClick]);

  useEffect(() => {
    window.addEventListener('mouseover', registerMouseOverHandler);
    return () => {
      window.removeEventListener('mouseover', registerMouseOverHandler);
    };
  }, [registerMouseOverHandler]);

  return (
    <div className={cx('mt-8 relative', (isLoading || isFetchingImages) && 'cursor-progress')}>
      <Map
        id="map"
        className={cx('w-full', abbr && 'cursor-pointer')}
        onClick={handleClick}
      />
      {abbr && (
        <img
          alt={`State ${abbr}`}
          style={{
            opacity: validStates.some(state => state.stateAbbr === abbr) ? 1 : 0.7
          }}
          src={`/assets/states-overlays/${abbr}.png`}
          className="absolute top-0 left-0 w-full pointer-events-none"
        />
      )}
    </div>
  );
};
