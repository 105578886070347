import { useRouter } from 'next/router';
import { ROUTES } from 'common/constants';
import cx from 'classnames';
import { Button, Modal, Select } from 'antd';
import { StatesMap } from 'components/StatesMap';
import React, { useCallback, useMemo, useState } from 'react';
import { ClientSection } from 'components/ClientSection';
import Link from 'next/link';
import { useAuth } from 'context/AuthContext';
import EnvelopeIcon from 'public/assets/icons/envelope.svg';
import PhoneIcon from 'public/assets/icons/phone.svg';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useUserValidStates } from 'hooks/useUserValidStates';

interface Props {
  className?: string;
  showMap?: boolean;
  selectedStateAbbr?: string;
}

export const StatesPicker = ({ className, showMap, selectedStateAbbr }: Props) => {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const { validStates, isLoading, allStates } = useUserValidStates();
  const [licensedModal, setLicensedModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const options: DefaultOptionType[] = useMemo(
    () => allStates?.map(state => ({ label: `${state.name} (${state.stateAbbr})`, value: state.stateAbbr })) || [],
    [allStates]
  );

  const handleStateClick = useCallback((abbr: string) => {
    if (!isAuthenticated) {
      setLoginModal(true);
      return;
    }

    if (validStates?.some(state => state.stateAbbr === abbr)) {
      setIsRedirecting(true);
      router.push({ pathname: ROUTES.FILING, query: { state: abbr } });
    } else {
      setLicensedModal(true);
    }
  }, [isAuthenticated, router, validStates]);

  return (
    <div className={cx('flex flex-col', className)}>
      {isAuthenticated && (
        <Select
          showSearch
          style={{ width: 300 }}
          className="mx-auto"
          value={selectedStateAbbr}
          options={options}
          filterOption={(input, option) => (option?.label || '').toString().toLowerCase().includes(input.toLowerCase())}
          onSelect={handleStateClick}
          loading={isLoading || isRedirecting}
          placeholder="Select state"
        />
      )}
      {showMap && (
        <StatesMap
          isLoading={isRedirecting}
          onStateClick={handleStateClick}
          validStates={validStates || []}
        />
      )}

      <Modal open={licensedModal} footer={null} onCancel={() => setLicensedModal(false)}>
        <ClientSection className="text-center" title="Selected state is not able to file" vertical>
          <h2 className="text-3xl mb-4 mt-4">VIA not licensed in this state. </h2>
          <p className="mb-4 mx-4">
            If you or your agency holds a Surplus Lines license, we are able to file on your behalf. Please contact us
          </p>
          <div className="flex flex-col gap-4 items-center justify-center">
            <a className="flex gap-4 items-center text-accent" href="mailto:gina@valleyinsllc.com">
              <div className="w-8 flex items-center justify-center">
                <EnvelopeIcon className="h-6 fill-accent"/>
              </div>
              gina@valleyinsllc.com
            </a>
            <a href="tel:2534663296" className="flex gap-4 text-accent">
              <div className="w-8 flex items-center justify-center">
                <PhoneIcon className="h-6 text-center stroke-accent"/>
              </div>
              253-466-3296
            </a>
          </div>
        </ClientSection>
      </Modal>

      <Modal open={loginModal} footer={null} onCancel={() => setLoginModal(false)}>
        <ClientSection className="text-center" title="You are logged out" vertical>
          <h2 className="text-3xl mb-4 mt-4">Log in to submit the filing</h2>
          <p className="mb-4 mx-4">
            Only logged in users are able to submit the filing.
            Don’t have an account? <Link href={ROUTES.SIGNUP}>Sign up now</Link>
          </p>
          <div className="mt-4 flex flex-col gap-4 justify-center items-center">
            <Button type="primary" onClick={() => router.push({ pathname: ROUTES.LOGIN })}>
              Log in
            </Button>
          </div>
        </ClientSection>
      </Modal>
    </div>
  );
};
