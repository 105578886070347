import { useAuth } from 'context/AuthContext';
import { useCurrentUser, useUserStateSettings } from 'queries/useUser';
import { useMemo } from 'react';
import { useFilingStates } from 'queries/useFilingState';
import { sortBy } from 'lodash';

export const useUserValidStates = () => {
  const { isAuthenticated } = useAuth();
  const { data: currentUser } = useCurrentUser({ enabled: isAuthenticated });
  const { data, isLoading } = useFilingStates({}, {
    enabled: isAuthenticated
  });

  const { data: userStateSettings, isLoading: isLoadingUserStateSettings } = useUserStateSettings(currentUser?.id || '', {
    enabled: isAuthenticated && Boolean(currentUser)
  });

  const validStates = useMemo(() => {
    const validStates = data?.filter(state => state?.stateSettings?.isStateVisible);

    for (const settings of userStateSettings || []) {
      if(settings.isStateVisible && settings.filingState) {
        const state = validStates?.find(state => state.stateAbbr === settings.filingState?.stateAbbr);

        if(!state) {
          validStates?.push(settings.filingState);
        }
      } else if(settings.isStateVisible === false && settings.filingState) {
        const state = validStates?.find(state => state.stateAbbr === settings.filingState?.stateAbbr);

        if(state) {
          validStates?.splice(validStates.indexOf(state), 1);
        }
      }
    }

    return sortBy(validStates, 'name')
  }, [data, userStateSettings]);

  return {
    validStates,
    allStates: data,
    isLoading: isLoading || isLoadingUserStateSettings
  }
}
